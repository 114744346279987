<template>



  <div class="">
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :selected-item="selectedItem"
      @refetch-data="refetchData"
    />
    <uploader
      :is-add-new-user-sidebar-active.sync="isUploaderActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :category="selectedCategory"
      @refetch-data="refetchData"
    />


<!-- Table Container Card -->
<b-card
  no-body
  class="mb-0  match-height" 
>
  <content-with-sidebar class="blog-wrapper match-height" >
    <div class="ml-2 mb-2 mt-2">

<!-- Table Top -->
<b-row  >

  <!-- Per Page -->
  <b-col
    cols="12"
    md="6"
    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
  >
    <label>Show</label>
    <v-select
      v-model="perPage"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="perPageOptions"
      :clearable="false"
      class="per-page-selector d-inline-block mx-50"
    />
    <label>entries</label>
  </b-col>

  <!-- Search -->
  <b-col
    cols="12"
    md="6" 
  >
    <div class="d-flex align-items-center justify-content-end">
      <b-form-input
        v-model="searchQuery"
        class="d-inline-block mr-1"
        placeholder="Search..."
      />
    
    </div>
  </b-col>
</b-row>

</div>

    <!-- blogs -->
    <b-row class="blog-list-wrapper match-height">
      <b-col
    
        md="12"  style="min-height:300px;"
      >

      
         <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchInstructers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(img)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.asseturl"
                :text="avatarText(data.item.membername)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-instructer-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.membername }} 
            </b-link>
              <small class="text-muted">@{{ data.item.membermail }}</small>
        
          </b-media>
        </template>

            <template #cell(title)="data">
          <b-media vertical-align="center">
           
            <b-link
              :to="{ name: 'cms-blog-edit',query: { p: data.item.id }, params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.title }} 
            </b-link>
              <small class="text-muted"> {{ data.item.author }}</small>
        
          </b-media>
        </template>

   

        <template #cell(createdAt)="data">
          <div class="text-nowrap">
          
            <span class="align-text-top text-capitalize">{{  data.item.createdAt | formattedDate     }}</span>
        </div>
        </template>

  <!-- Column: User -->
  <template  #cell(featuredImage)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-button
                variant=".btn-flat-dark"
                @click="editCategory(data.item)"
           
              >
              <b-img-lazy fluid thumbnail  width="100"  style="max-height:50px;" height="50" :src="data.item.asseturl"    />
              </b-button>
             
            </template>
             
          </b-media>
        </template>
        <!-- Column: Role -->
        <template #cell(upperCategory)="data">
          <div class="text-nowrap">
            
            <span class="align-text-top text-capitalize">{{ Boolean(data.item.Parent)?  data.item.Parent.name : '' }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(assetType)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
          {{  data.item.assettype}}
         
          </b-badge>
        </template>
             <template #cell(isImported)="data">
          <div class="text-nowrap">
            
             <b-badge
            pill
            v-if="data.item.isImported"
            variant="light-success"
            class="text-capitalize"
          >
          IMPORTED
         
          </b-badge>
            <b-badge
            pill
            v-if="!data.item.isImported"
            variant="light-secondary"
            class="text-capitalize"
          >
          {{ moment( data.item.createdAt).format('Do MMMM YYYY,  hh:MM ')}}
         
          </b-badge>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data" >
          

           
             
               <b-button
                variant=".btn-flat-danger"
                @click="deleteItem(data.item)"
           
              >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
             </b-button>
            
            
          
        </template>

      </b-table>
      </b-col>
      <b-col cols="12">
        <!-- pagination -->
  
        <div class="my-2">
          <b-pagination
            v-model="currentPage"
            align="center"
            :total-rows="totalUsers"
              :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>

    <!--/ blogs -->

    <!-- sidebar -->
    <div
      slot="sidebar"
      class="blog-sidebar py-2 py-lg-0 match-height"
    >
    <div class="blog-categories mt-3">
        
      <b-button
                variant="primary"
                @click="openUploader()"
              >
                <span class="text-nowrap">Add Item</span>
              </b-button>

      </div>
      

      <!-- categories -->
      <div class="blog-categories mt-3">
        <h6 class="section-label mb-1">
          Categories
        </h6>
        <b-link>
            <b-avatar
              rounded
              size="32"
              :variant="tagsColor()"
              class="mr-75"
            >
              <feather-icon
               icon="ArrowRightCircleIcon"
                size="16"
              />
            </b-avatar>
          </b-link>
        <b-button  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="flat-primary" @click="updatSelectedCategory(null)" > 
            <div class="blog-category-title text-body">
            All
            </div>
          </b-button>
        <div
          v-for="category in allCategories"
          :key="category.icon"
          class="d-flex justify-content-start align-items-center mb-75"
        >
          <b-link>
            <b-avatar
              rounded
              size="32"
              :variant="tagsColor(category.title)"
              class="mr-75"
            >
              <feather-icon
               icon="ArrowRightCircleIcon"
                size="16"
              />
            </b-avatar>
          </b-link>
          <b-button  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      :variant="selectedCategory!=null&&selectedCategory.id==category.id?'primary':'flat-primary'" @click="changeCategory(category)" > 
            <div class="blog-category-title text-body">
              {{ category.title }}
            </div>
          </b-button>
        </div>
      </div>
      <!--/ categories -->
    </div>
    <!--/ sidebar -->
  </content-with-sidebar>
</b-card>
  </div>

</template>

<script>
import {
 BTable,BImgLazy, BRow,BDropdown, BCol, BButton, BCard, BFormInput, BCardText, BCardTitle, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination,
} from 'bootstrap-vue'
 
import { kFormatter } from '@core/utils/filter'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import userStoreModule from '../departmentStoreModule'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './DepartmentsListFilters.vue'
import useUsersList from './useDepartmentsList'
import moment from 'moment'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserListAddNew from './DepartmentListAddNew.vue'
import Uploader from './Uploader.vue'
export default {
  components: {
    BTable,BButton, BDropdown,vSelect,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BImgLazy,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    ContentWithSidebar,
    UserListAddNew,
    Uploader
  },
  directives: {
    Ripple,
  },
  filters: {
        formattedDate: function (value) {
          if (value) {
        return moment(String(value)).format('MM/DD/YYYY HH:mm')
    }
        }
    },
    provide() {
        const categorydata = {}

        Object.defineProperty(categorydata, "selectedCategory", {
            enumerable: true,
            get: () => this.selectedCategory,
        })

        return {
          
            categorydata,
        }
    },
  data() {
    return {
      search_query: '',
      timerInterval: null,
      selectedItem:{}
       
    }
  },
  created() {
   // this.$http.get('/blog/list/data').then(res => { this.blogList = res.data })
  //  this.$http.get('/blog/list/data/sidebar').then(res => { this.blogSidebar = res.data })
  },
  methods: {
    resetFile() {
      this.file = null;
      this.profileFile = null;
    },
    clearFiles() {
      this.$refs["file-input"].reset();
    },
    deleteItem(coursedata){
console.log(coursedata);
coursedata.deleted=true;
 store
      .dispatch('apps-librarylist/deleteItem', coursedata)
      .then(response => {

    var result= response.data.success;
    if (result!=null&&result!=undefined&&result==100)
    {
      coursedata.deleted=true;

       this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.refetchData();

          this.isLoading = false;
          this.isUpdated = false;

        //  fetchSurveyQuestions();
        


    } else 
    {
       this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
    }
       
        
      })
      .catch(() => {
         this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
      })
},

    changeCategory(e)
    {
      console.log("change category");
      console.log(e);
      this.selectedCategory= JSON.parse(JSON.stringify(e));
   // this.isAddNewUserSidebarActive=true;
   
    },  

    editCategory(e)
    {
       
      console.log(e);
      this.selectedItem= JSON.parse(JSON.stringify(e));
      this.selectedItem.LibraryCategories=JSON.parse(JSON.stringify(selectedItem.LibraryCategories));
     this.isAddNewUserSidebarActive=true;
   
    }, 

    deletePosition(e)
    {
      this.selectedCategory=e;
    this.isAddNewUserSidebarActive=true;
    console.log(e);
    },
     newCategory()
    {
      this.selectedCategory={
        id:null,
        name:null,
        description:null,
        upperCategory: null,
        Parent:null,

      };
    this.isAddNewUserSidebarActive=true;
    
    },
    openUploader()
    {
      
    this.isUploaderActive=true;
    
    },
    ajax() {
      this.$swal({
        title: 'Title for asset',
        input: 'text',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Next',
        showLoaderOnConfirm: true,
        preConfirm(login) {
          if (!login) return null
          return fetch(`//api.github.com/users/${login}`)
            .then(response => {
              if (!response.ok) {
                throw new Error(response.statusText)
              }
              return response.json()
            })
            .catch(error => {
              this.$swal.showValidationMessage(`Request failed:  ${error}`)
            })
        },
      }).then(result => {
        if (result.value) {
          this.$swal({
            title: `${result.value.login}'s avatar`,
            imageUrl: result.value.avatar_url,
            customClass: { confirmButton: 'btn btn-primary' },
          })
        }
      })
    },
    kFormatter,
    tagsColor(tag) {
      if (tag === 'HR Commmunication') return 'light-info'
      if (tag === 'Weekly News') return 'light-danger'
      if (tag === 'Announcement') return 'light-primary'
      if (tag === 'Memorandum') return 'light-warning'
      if (tag === 'Info') return 'light-success'
      return 'light-primary'
    },

    updatSelectedCategory(category)
    {
        this.selectedCategory=category
    },
      moment: function () {
    return moment();
  }
  },
   setup() {
    const USER_APP_STORE_MODULE_NAME = 'apps-librarylist'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)
    const isUploaderActive = ref(false)
    
 


    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchInstructers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      allCategories,
    selectedCategory,
    

    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,
      isUploaderActive,
      fetchInstructers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      allCategories,
    selectedCategory
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
